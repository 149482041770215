import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout/Layout';
import SEO from '../../components/SEO';
import Resend from '../../components/Registration/SignUpResend';
import { navigate } from 'gatsby';

const SignUpResend = ({ location }: { location: any }) => {
  const params = new URLSearchParams(location.search);
  const email = params.get('unconfirmedEmail');
  return (
    <>
      <Layout>
        <SEO title="Solicita tu código" />
        <Resend unconfirmedEmail={email} />
      </Layout>
    </>
  );
};

export default SignUpResend;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
