import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import background from '../../images/Registration/pluma.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '72px',
      backgroundColor: `#FFF`,
      position: 'relative',
      '& .MuiOutlinedInput-root': {
        height: 48,
      },
      '& .MuiInputLabel-outlined': {
        color: '#AAAAAA',
        fontSize: 16,
        [theme.breakpoints.down(501)]: {
          fontSize: 14,
        },
      },
      '& .MuiSvgIcon-root': {
        width: 20,
        height: 20,
      },
      [theme.breakpoints.down(501)]: {
        '& label': {
          fontSize: `14px`,
        },
        '& input': {
          fontSize: `15px`,
        },
      },
    },
    formTerms: {
      marginLeft: 15,
      [theme.breakpoints.down(501)]: {
        marginLeft: `-10px`,
      },
      '& svg': {
        width: 18,
        height: 18,
      },
      '& span': {
        fontSize: 14,
        [theme.breakpoints.down(501)]: {
          fontSize: 10,
          lineHeight: `13px`,
        },
      },
    },
    dialogActions: {
      justifyContent: 'flex-start !important',
      padding: '7% 0% 0% 7% !important',
      '& span': {
        textTransform: 'capitalize',
      },
    },
    dialogContent: {
      width: 'calc(100% - 1px) !important',
      maxHeight: 'calc(100% - 1px) !important',
      margin: '0px !important',
    },
    dialog: {
      width: '50%',
      top: 0,
      left: 'auto !important',
      right: '0px !important',
      height: '100%',
      position: 'fixed',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    section: {
      position: 'relative',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo desvanecido
    },
    subtitle: {
      '& b': {
        color: `#F8B133`,
        textDecoration: `underline`,
        marginLeft: 2,
      },
      '& p': {
        float: `left`,
        textAlign: `center`,
        marginTop: `19px`,
        marginBottom: `45px`,
        [theme.breakpoints.down(501)]: {
          marginTop: `9px`,
          marginBottom: '31px',
        },
      },
      margin: `auto`,
    },
    container: {
      display: 'flex',
      color: '#004F71',
      paddingLeft: `0%`,
      paddingRight: `0%`,
      height: 850,
      [theme.breakpoints.down(501)]: {
        height: 'auto',
        marginBottom: '20px',
      },
      '& h1': {
        textAlign: `center`,
        fontSize: 32,
        [theme.breakpoints.down(501)]: {
          fontSize: 30,
          fontWeight: `600`,
        },
      },
      '& p': {
        [theme.breakpoints.down(501)]: {
          fontSize: 15,
        },
      },
      '& h2': {
        [theme.breakpoints.down(501)]: {
          fontSize: 18,
        },
      },
      [theme.breakpoints.up(1921)]: {
        paddingLeft: `0%`,
        paddingRight: `0%`,
        margin: `auto`,
      },
    },
    '@global': {
      body: {
        backgroundColor: theme.palette.common.white,
      },
      header: {
        display: 'none',
      },
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    feather: {
      float: `right`,
      width: `100%`,
      height: `100%`,
      backgroundImage: `url(${background})`,
      backgroundRepeat: `no-repeat`,
      backgroundPosition: `right`,
      backgroundSize: `cover`,
      // backgroundColor:`#FF3`,
    },
    formGroup: {
      paddingTop: 38,
    },
    submitGroup: {
      '& button': {
        borderRadius: `30px`,
        backgroundColor: `#F8B133`,
        marginTop: 33,
        height: 48,
        fontSize: 20,
        fontWeight: `600`,
        textTransform: `capitalize`,
        '&:hover': {
          background: '#074F71',
          color: `#FFF`,
        },
      },
    },
    formControl: {
      width: `100%`,
    },
    indeterminateColor: {
      color: '#F8B133',
    },
    selectAllText: {
      fontWeight: 500,
    },
    selectedAll: {
      backgroundColor: '#FAB234',
      '&:hover': {
        backgroundColor: '#FAB234',
      },
    },
    helperText: {
      color: `#f44336`,
    },
  })
);
export default useStyles;
