import React, { useState, useEffect } from 'react';
import { useFormik, useFormikContext } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import useStyles from './Register.styles';
import { Container, Grid, Typography, Hidden } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import APIProspect from '../../services/APIProspect.service';
import TransitionAlert from '../Common/Alert';
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import NavBarV2 from '../Header/NavBarV2';

const validationSchema = yup.object({
  email: yup.string().email('Ingresa un correo valido').required('Correo es requerido'),
});

const SignUpResend = ({ unconfirmedEmail }: { unconfirmedEmail: any }) => {
  const classes = useStyles();
  const [submit, setSubmit] = React.useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await APIProspect.post('signup/refresh-code', values)
        .then((response) => {
          if (response) {
            if (typeof window !== `undefined`) {
              navigate('/signup/confirmation', {
                state: { values },
              });
            }
          } else {
            setAlertOpen(true);
            setAlertTitle('Error al ingresar este correo electrónico.');
          }
        })
        .catch((error) => {
          setAlertOpen(true);
          setAlertTitle('Error al ingresar este correo electrónico.');
        });
    },
  });

  useEffect(() => {
    if (unconfirmedEmail) {
      formik.setFieldValue('email', unconfirmedEmail);
    }
  }, []);

  return (
    <section id="registerPage" className={classes.root}>
      <NavBarV2 />
      <Helmet bodyAttributes={{ class: 'registerPage' }} />
      <Container className={classes.container} maxWidth={false}>
        <Hidden smDown>
          <Grid item md={6} className={classes.feather} />
        </Hidden>

        <Grid className={classes.formGroup} item xs={12} md={6} lg={4}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container direction="column">
              <Typography component="h1" variant="h5">
                Código confirmación
              </Typography>
              <Grid className={classes.subtitle}>
                <p> Ingresa tu correo y solicita un nuevo código </p>{' '}
              </Grid>
            </Grid>

            <Container>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={10} md={9} lg={9}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Correo electrónico"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    variant="outlined"
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid className={classes.submitGroup} item xs={10} md={9} lg={9}>
                  <Button
                    fullWidth
                    startIcon={submit ? <CircularProgress size="1rem" /> : null}
                    disabled={submit}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    Solicitar código
                  </Button>
                </Grid>
                <Grid item xs={10} md={9} lg={9}>
                  {alertOpen && (
                    <TransitionAlert
                      setAlertOpen={setAlertOpen}
                      open={alertOpen}
                      title={alertTitle}
                      description="Por favor, intenta nuevamente."
                      severity="error"
                    />
                  )}
                </Grid>
              </Grid>
            </Container>
          </form>
        </Grid>
      </Container>
    </section>
  );
};

export default SignUpResend;
