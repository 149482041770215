import axios from 'axios';
import { initializeAxiosMockAdapter, isMockEnabled } from '../mock/mock.config';

const APIProspect = axios.create({
  baseURL: process.env.GATSBY_MS_SIGNUP_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

if (isMockEnabled()) {
  initializeAxiosMockAdapter(APIProspect);
}

export default APIProspect;
